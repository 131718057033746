<template>
  <b-card-code
    no-body
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      :title="`${recordToDelete.isPermanent ? 'Are you sure you want to permanently delete this record?' : 'Are you sure you want to delete this record?'}`"
      centered
      ok-title="Delete"
      @ok="deleteModalHandler"
    >
      <div>
        {{ recordToDelete.isPermanent ? 'Permanently deleted records cannot be recovered later.' : 'In case you might want to recover it later, you can enable the "With deleted" toggle to find it and either recover or permanently delete it.' }}
      </div>
    </b-modal>
    <b-modal
      v-if="!isLoading"
      id="modal-edit"
      ref="modal"
      title="Change user status"
      hide-footer
      size="md"
      @hidden="closeModal"
    >
      <p>Are you sure that you want to change status in {{ selectedUserValidated.length }} items?</p>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="submitStatusesChanging(1)"
        >
          Change to active
        </b-button>
        <b-button
          variant="primary"
          @click="submitStatusesChanging(0)"
        >
          Change to inactive
        </b-button>
      </div>
    </b-modal>
    <b-card-body>
      <b-form @submit.prevent="searchRows()">
        <p v-if="companyId || companyName">
          Company: <a
            :href="`/company-management/summary/${companyId}`"
          >
            {{ companyName }}
          </a>
        </p>
        <b-form-row class="align-items-end">
          <b-col md="1">
            <feather-icon
              v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
              v-b-tooltip.hover
              icon="XIcon"
              title="Reset filters"
              size="24"
              class="text-body align-middle mr-25"
              @click="resetFilters"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
    <b-row class="px-2 mb-2">
      <b-col
        v-if="$can('create', 'hardware')"
        order-md="2"
        order="1"
        class="text-right"
      >
        <div
          v-if="$can('delete', 'users')"
          label-class="label-class"
          class="with-deleted-checkbox"
        >
          <span>With deleted</span>
          <b-form-checkbox
            class="mr-0"
            switch
            inline
            @change="setFilterWithTrashed"
          />
        </div>
      </b-col>
    </b-row>

    <table-mobile-solo
      :search="searchValue"
      :handle-search="handleMobileSearch"
      :mobile-search-fields="mobileSearchFields"
      :items="items"
      :fields="fields"
      :rules="{
        city(value) {
          return value.addresses.city
        },
        state(value) {
          return value.addresses.state
        },
        postcode(value) {
          return value.addresses.postcode
        },
        country(value) {
          return value.addresses.country.name
        },
        company(value) {
          return value.company.name
        },

      }"
      :pagination="{
        pagination: pagination,
        perPage: perPage,
        currentPage: page,
        pageSizeOptions: pageSizeOptions,
        enabled: true,
        perPageChanged: perPageChanged,
        changePage: changePage,
      }"
      :key-value="'email'"
      :actions="[{
        type: 'secondary',
        icon: 'LogInIcon',
        label: 'Login',
        click: ({id}) => loginAction(id),
        visible: () => $can('reset-password', 'users'),
      }, {
        type: 'secondary',
        icon: 'SendIcon',
        label: 'Reset password',
        click: ({id}) => resetPasswordAction(id),
        visible: () => $can('reset-password', 'users'),
      }, {
        type: 'secondary',
        icon: 'Edit2Icon',
        label: 'Edit',
        click: ({id}) => toEdit(id),
        visible: () => $can('update', 'users'),
      }, {
        type: 'danger',
        icon: 'TrashIcon',
        label: 'Delete',
        click: ({id}) => deleteHandler(id),
        visible: ({is_deleted}) => !is_deleted && $can('delete', 'users'),
      }, {
        type: 'primary',
        icon: 'TrashIcon',
        label: 'Permanently delete',
        click: ({id}) => deleteHandler(id, true),
        visible: ({is_deleted}) => is_deleted && $can('delete', 'users'),
      }, {
        type: 'primary',
        icon: 'RefreshCcwIcon',
        label: 'Recover',
        click: ({id}) => recoverRow(id),
        visible: ({is_deleted}) => is_deleted && $can('delete', 'users'),
      }]"
    />

    <b-card-body>
      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        compact-mode
        :columns="fields"
        :rows="items"
        :total-rows="pagination.total"
        :is-loading="isLoading"
        class="user-table hide-mobile-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: { field: orderBy, type: sortedBy }
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          disableSelectInfo: false,
          selectionInfoClass: 'table-selected-row-info-panel',
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="columnFilterFn"
        @on-selected-rows-change="selectionChanged"
      >
        <template slot="selected-row-actions">
          <div
            v-b-tooltip.hover
            :title="!selectedUserValidated.length ? 'Changing statuses is not available for removed users, please make sure you recover them first or just unselect them if you have selected those unintentionally' : ''"
          >
            <b-button
              v-b-modal.modal-edit
              variant="primary"
              class="ml-auto"
              :class="{'disabled btn-disabled': !selectedUserValidated.length}"
            >
              Change statuses
            </b-button>
            <b-button
              v-b-modal.modal-delete
              variant="primary"
              class="ml-1"
            >
              Bulk Delete
            </b-button>
          </div>
        </template>
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- <b-dropdown-item
                  v-if="$can('view', 'users')"
                  @click="redirectToSummary(props.row.id)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>Show info</span>
                </b-dropdown-item> -->
                <b-dropdown-item
                  v-if="$can('reset-password', 'users')"
                  @click.prevent="loginAction(props.row.id)"
                >
                  <feather-icon
                    icon="LogInIcon"
                    class="mr-50"
                  />
                  <span>Login</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('reset-password', 'users')"
                  @click.prevent="resetPasswordAction(props.row.id)"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  <span>Reset password</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('update', 'users')"
                  @click.prevent="toEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.is_deleted && $can('delete', 'users')"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'users')"
                  @click.prevent="deleteHandler(props.row.id, true)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Permanently delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'users')"
                  @click.prevent="recoverRow(props.row.id)"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span>Recover</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'company.name'">
            {{ props.row.company.name }} <p
              v-if="!isClient() && props.row.company.type"
              style="font-weight: 600"
            >[{{ props.row.company.type.name }}]</p>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-variant="secondary"
      ok-only
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
    <b-modal
      v-if="!isLoading"
      id="modal-delete"
      ref="modalDeleteBulk"
      title="Delete user(s)?"
      hide-footer
      size="md"
      @hidden="closeModal"
    >
      <p>Are you sure that you want to delete {{ $refs.VueGoodTable.selectedRows.length }} user(s)?</p>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="submitDeleteBulk(1)"
        >
          Yes
        </b-button>
        <b-button
          variant="secondary"
          @click="submitDeleteBulk(0)"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BForm,
  BFormRow,
  BSpinner,
  BModal,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, watch } from '@vue/composition-api'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '@axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormCheckbox,
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BForm,
    BFormRow,
    BSpinner,
    BModal,
    VueGoodTable,
    TableMobileSolo,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      isLoading: true,
      uppdateRouteName: 'user-management-update',
      currentRoute: this.$route.path,

      deleteErrorPopUpInfo: {},
      searchValue: '',

      selectedUserValidated: [],

      recordToDelete: {
        id: null,
        isPermanent: false,
      },
      mobileTableOn: window.innerWidth < 745,

      mobileSearchFields: {
        first_name: '',
        last_name: '',
        email: '',
        'company.name': '',
      },

      fields: [
        {
          field: 'first_name',
          label: 'First Name',
          filterOptions: {
            enabled: true,
            placeholder: 'First Name',
            filterValue: '',
          },
        },
        {
          field: 'last_name',
          label: 'Last Name',
          filterOptions: {
            enabled: true,
            placeholder: 'Last Name',
            filterValue: '',
          },
        },
        {
          field: 'email',
          label: 'Email / Username',
          filterOptions: {
            enabled: true,
            placeholder: 'Email',
            filterValue: '',
          },
        },
        {
          field: 'company.name',
          mobileSpecial: 'company',
          label: 'Company Name',
          filterOptions: {
            enabled: true,
            placeholder: 'Company Name',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.city',
          mobileSpecial: 'city',
          label: 'Town / City',
          filterOptions: {
            enabled: true,
            placeholder: 'Town / City',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.state',
          mobileSpecial: 'state',
          label: 'State',
          filterOptions: {
            enabled: true,
            placeholder: 'State',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.postcode',
          label: 'Zip / Post Code',
          mobileSpecial: 'postcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Zip / Post Code',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'addresses.country.name',
          mobileSpecial: 'country',
          label: 'Country',
          filterOptions: {
            enabled: true,
            placeholder: 'Country',
            filterValue: '',
          },
        },
        {
          field: 'phone_number1',
          label: 'Phone Number',
          filterOptions: {
            enabled: true,
            placeholder: 'Phone Number',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'is_active',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
            filterValue: '',
          },
          sortable: false,
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'users'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        const company = this.getCompany(this.$route.query.companyId)
        this.companyName = company.name
        this.companyId = this.$route.query.companyId
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  async mounted() {
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },
  computed: {
    ...mapState('account', ['userData']),
    ...mapState('userManagement', {
      items: 'items',
      statuses: 'statuses',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
      searchJoin: 'searchJoin',
    }),
    page() {
      return this.$route.query?.params || {}
    },
  },

  watch: {
    async $route(to) {
      const additionalPathname = to.fullPath.replace(to.path, '')
      if (!additionalPathname) {
        await this.clearAllData()
        this.companyName = ''
      }
    },
    recordToDelete(val) {
      if (val.id) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
    items() {
      if (this.items.length > 0) {
        this.fields = this.fields.map(item => {
          if (item.label === 'Status') {
            // eslint-disable-next-line no-param-reassign
            item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.statuses] } }
          }
          return item
        })
      }
    },
  },
  created() {
    window.addEventListener('resize', this.widthListener)
  },
  destroyed() {
    window.removeEventListener('resize', this.widthListener)
  },
  methods: {
    redirectToSummary(id) {
      // without setTimeout we will have scroll on table because dropdown can`t handle @hide event
      setTimeout(() => {
        this.$router.push(`summary/${id}`)
      }, 1)
    },
    handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }
      // this.searchValue = search
      this.columnFilterFn(search, true)
    },
    resetFilters() {
      this.resetFilter()
      this.searchValue = ''
    },
    widthListener() {
      // reset filters on change with 745px (more or less)
      if (window.innerWidth < 745 && !this.mobileTableOn) {
        this.mobileTableOn = true
        this.resetFilter()
      } else if (window.innerWidth >= 745 && this.mobileTableOn) {
        this.mobileTableOn = false
        this.resetFilter()
      }
    },
    isClient() {
      return this.userData.roles.includes('client') || this.userData.roles.includes('reseller')
    },
    ...mapActions('userManagement', {
      fetchItems: 'getItems',
      deleteRecord: 'deleteItem',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      changeItemsStatuses: 'changeItemsStatuses',
      resetPassword: 'resetPassword',
      getCompany: 'getCompany',
      recoverRecord: 'recoverItem',
      setJoin: 'setJoin',
    }),
    ...mapActions('account', ['userLogin']),
    async deleteHandler(id, isPermanent = false) {
      this.recordToDelete = {
        id,
        isPermanent,
      }
    },
    async deleteModalHandler() {
      const errorRes = await this.deleteRow(this.recordToDelete.id)
      this.deleteErrorPopUpInfo = errorRes
      this.recordToDelete = {
        id: null,
        isPermanent: false,
      }
    },
    async submitDeleteBulk(iSent) {
      if (iSent) {
        await axios.delete('/user/records', {
          params: {
            ids: this.$refs.VueGoodTable.selectedRows.map(item => item.id),
          },
        }).catch(error => {
          console.warn(error)
          const { status } = error.response

          if (status === 422) {
            let detailContent = ''

            const userName = error.response.data.fields.user_name

            if (error?.response?.data?.fields) {
              detailContent += `You cannot remove ${userName} user because one of the entities has the following relation(s):<br>\n`
              Object.keys(error.response.data.fields).forEach(field => {
                if (field === 'user_name') return
                detailContent += ` - ${field}: \n 
                <ul class="mb-1">
                  ${error.response.data.fields[field].map(item => `<li>${item}</li>`).join('')}
                  </ul>`
              })
            }

            this.showModal({
              title: 'Validation error',
              content: detailContent,
              icon: 'BellIcon',
              variant: 'danger',
            })

            this.deleteErrorPopUpInfo = {
              title: 'Validation error',
              content: detailContent,
              icon: 'BellIcon',
              variant: 'danger',
            }
          }
        })
      }
      this.fetchItems()
      this.$refs.modalDeleteBulk.hide()
    },
    closeModal() {
      this.$refs.modal.hide()
    },
    async getData() {
      const {
        pageNum, perPage, search, searchFields, orderBy, sortedBy,
      } = this.$route.query
      this.page = pageNum ?? 1
      this.perPage = perPage ?? 25

      const params = {
        ...this.$route.query,
      }

      Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

      if (search || searchFields || orderBy || sortedBy) {
        await this.fetchtableData({
          ...params,
        })
      } else {
        await this.fetchtableData({ pageNum: this.page, perPage: this.perPage })
      }
      this.setSearchParams(search, searchFields)
      this.setOrderBy(orderBy)
      this.setSortedBy(sortedBy)
    },
    selectionChanged(props) {
      this.selectedUserValidated = props.selectedRows ? props.selectedRows.filter(user => !user.is_deleted) : []
    },
    async submitStatusesChanging(activeStatus) {
      try {
        await this.changeItemsStatuses({ ids: this.selectedUserValidated.map(item => item.id), is_active: activeStatus })
        await this.getData()
        this.$refs.modal.hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async resetPasswordAction(id) {
      try {
        await this.resetPassword(id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async loginAction(id) {
      try {
        const { abilities } = await this.userLogin(id)
        this.$ability.update(abilities)
        await this.$router.push({ name: 'home' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    ...mapMutations('hardwareTypes', ['RESET_STATE']),
    rowStyleClassFn(row) {
      return row.is_deleted ? 'deleted-class' : ''
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .user-table {
    .deleted-class {
      .vgt-checkbox-col {
        background-color: rgba(255, 0, 0, 0.185) !important;
        input {
        }
      }
      .vgt-left-align {
        background-color: rgba(255, 0, 0, 0.185) !important;
      }
    }
  }
  .table-selected-row-info-panel{
    display: flex;
    align-items: center;
    padding: 10px;
    a{
      color: red
    }
  }
  .vgt-pull-right{
    float: unset !important;
    margin-left: auto;
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
</style>
